
if (import.meta.hot) {
  import.meta.hot.accept((mod) => {
    const router = import.meta.hot.data.router
    const generateRoutes = import.meta.hot.data.generateRoutes
    if (!router || !generateRoutes) {
      import.meta.hot.invalidate('[nuxt] Cannot replace routes because there is no active router. Reloading.')
      return
    }
    router.clearRoutes()
    const routes = generateRoutes(mod.default || mod)
    function addRoutes (routes) {
      for (const route of routes) {
        router.addRoute(route)
      }
      router.replace('')
    }
    if (routes && 'then' in routes) {
      routes.then(addRoutes)
    } else {
      addRoutes(routes)
    }
  })
}

export function handleHotUpdate(_router, _generateRoutes) {
  if (import.meta.hot) {
    import.meta.hot.data ||= {}
    import.meta.hot.data.router = _router
    import.meta.hot.data.generateRoutes = _generateRoutes
  }
}
import { default as abuseReportsN0C3gxmbWJMeta } from "/home/admin/www/pronoms.fr/release/2025-01-28--20-32-14/pages/admin/abuseReports.vue?macro=true";
import { default as _91id_93MbQ0xT8tg7Meta } from "/home/admin/www/pronoms.fr/release/2025-01-28--20-32-14/pages/admin/audit-log/[username]/[id].vue?macro=true";
import { default as editor0ezV4aHyYLMeta } from "/home/admin/www/pronoms.fr/release/2025-01-28--20-32-14/pages/admin/blog/editor.vue?macro=true";
import { default as indexZZurTXz5LKMeta } from "/home/admin/www/pronoms.fr/release/2025-01-28--20-32-14/pages/admin/index.vue?macro=true";
import { default as moderationTDSte18Sf1Meta } from "/home/admin/www/pronoms.fr/release/2025-01-28--20-32-14/pages/admin/moderation.vue?macro=true";
import { default as pendingBansATfut1vkiiMeta } from "/home/admin/www/pronoms.fr/release/2025-01-28--20-32-14/pages/admin/pendingBans.vue?macro=true";
import { default as profiles4JWqlseSt1Meta } from "/home/admin/www/pronoms.fr/release/2025-01-28--20-32-14/pages/admin/profiles.vue?macro=true";
import { default as expensesZ5EftJ3A3NMeta } from "/home/admin/www/pronoms.fr/release/2025-01-28--20-32-14/pages/admin/timesheets/expenses.vue?macro=true";
import { default as indexNG05wCU7NHMeta } from "/home/admin/www/pronoms.fr/release/2025-01-28--20-32-14/pages/admin/timesheets/index.vue?macro=true";
import { default as overviewgmjEC1TT3FMeta } from "/home/admin/www/pronoms.fr/release/2025-01-28--20-32-14/pages/admin/timesheets/overview.vue?macro=true";
import { default as awaitingcSw9Dxo0cJMeta } from "/home/admin/www/pronoms.fr/release/2025-01-28--20-32-14/pages/admin/translations/awaiting.vue?macro=true";
import { default as missingkGKcEFFwPmMeta } from "/home/admin/www/pronoms.fr/release/2025-01-28--20-32-14/pages/admin/translations/missing.vue?macro=true";
import { default as usersKwWvgCgtyTMeta } from "/home/admin/www/pronoms.fr/release/2025-01-28--20-32-14/pages/admin/users.vue?macro=true";
import { default as apivkYTpx9sxUMeta } from "/home/admin/www/pronoms.fr/release/2025-01-28--20-32-14/pages/api.vue?macro=true";
import { default as _91slug_93gVNNaB2WiOMeta } from "/home/admin/www/pronoms.fr/release/2025-01-28--20-32-14/pages/blog/[slug].vue?macro=true";
import { default as indexGIIysmHh77Meta } from "/home/admin/www/pronoms.fr/release/2025-01-28--20-32-14/pages/blog/index.vue?macro=true";
import { default as _91_91year_93_938WOgrOCLiFMeta } from "/home/admin/www/pronoms.fr/release/2025-01-28--20-32-14/pages/calendar/[[year]].vue?macro=true";
import { default as _91year_93_45_91month_93_45_91day_93kTZ0KwzmP8Meta } from "/home/admin/www/pronoms.fr/release/2025-01-28--20-32-14/pages/calendar/[year]-[month]-[day].vue?macro=true";
import { default as adminWOo0fcw8aDMeta } from "/home/admin/www/pronoms.fr/release/2025-01-28--20-32-14/pages/census/admin.vue?macro=true";
import { default as indexGxEPfdGLcXMeta } from "/home/admin/www/pronoms.fr/release/2025-01-28--20-32-14/pages/census/index.vue?macro=true";
import { default as contactborhQ9E7rlMeta } from "/home/admin/www/pronoms.fr/release/2025-01-28--20-32-14/pages/contact.vue?macro=true";
import { default as designt9IM9oOWVDMeta } from "/home/admin/www/pronoms.fr/release/2025-01-28--20-32-14/pages/design.vue?macro=true";
import { default as english4MYHusM8gVMeta } from "/home/admin/www/pronoms.fr/release/2025-01-28--20-32-14/pages/english.vue?macro=true";
import { default as faquoiU4QuT9jMeta } from "/home/admin/www/pronoms.fr/release/2025-01-28--20-32-14/pages/faq.vue?macro=true";
import { default as inclusiveocRPyjeObpMeta } from "/home/admin/www/pronoms.fr/release/2025-01-28--20-32-14/pages/inclusive.vue?macro=true";
import { default as index_45home2pdieyBA8lMeta } from "/home/admin/www/pronoms.fr/release/2025-01-28--20-32-14/pages/index-home.vue?macro=true";
import { default as index_45localeKAp8LBx43GMeta } from "/home/admin/www/pronoms.fr/release/2025-01-28--20-32-14/pages/index-locale.vue?macro=true";
import { default as langswitchwP95o1T0J8Meta } from "/home/admin/www/pronoms.fr/release/2025-01-28--20-32-14/pages/langswitch.vue?macro=true";
import { default as licensehuTroliNQjMeta } from "/home/admin/www/pronoms.fr/release/2025-01-28--20-32-14/pages/license.vue?macro=true";
import { default as academicIWbnhtvGIwMeta } from "/home/admin/www/pronoms.fr/release/2025-01-28--20-32-14/pages/links/academic.vue?macro=true";
import { default as indexElA9vz3sE6Meta } from "/home/admin/www/pronoms.fr/release/2025-01-28--20-32-14/pages/links/index.vue?macro=true";
import { default as mediaStruVcI21qMeta } from "/home/admin/www/pronoms.fr/release/2025-01-28--20-32-14/pages/links/media.vue?macro=true";
import { default as translinguisticszKAuvbmrfQMeta } from "/home/admin/www/pronoms.fr/release/2025-01-28--20-32-14/pages/links/translinguistics.vue?macro=true";
import { default as zine8ov5rm1XcHMeta } from "/home/admin/www/pronoms.fr/release/2025-01-28--20-32-14/pages/links/zine.vue?macro=true";
import { default as names94qeODAon3Meta } from "/home/admin/www/pronoms.fr/release/2025-01-28--20-32-14/pages/names.vue?macro=true";
import { default as indexCkuEOxDHErMeta } from "/home/admin/www/pronoms.fr/release/2025-01-28--20-32-14/pages/nouns/index.vue?macro=true";
import { default as templatesdT4HlwgNHGMeta } from "/home/admin/www/pronoms.fr/release/2025-01-28--20-32-14/pages/nouns/templates.vue?macro=true";
import { default as peoplecKO4JDgJhXMeta } from "/home/admin/www/pronoms.fr/release/2025-01-28--20-32-14/pages/people.vue?macro=true";
import { default as privacyGCjf4l5GAbMeta } from "/home/admin/www/pronoms.fr/release/2025-01-28--20-32-14/pages/privacy.vue?macro=true";
import { default as _91username_937SxUShDMbBMeta } from "/home/admin/www/pronoms.fr/release/2025-01-28--20-32-14/pages/profile/[username].vue?macro=true";
import { default as card_45_91username_93LiXPa7TANsMeta } from "/home/admin/www/pronoms.fr/release/2025-01-28--20-32-14/pages/profile/card-[username].vue?macro=true";
import { default as editorf4VXGVfOyAMeta } from "/home/admin/www/pronoms.fr/release/2025-01-28--20-32-14/pages/profile/editor.vue?macro=true";
import { default as anykWmwfa2nKYMeta } from "/home/admin/www/pronoms.fr/release/2025-01-28--20-32-14/pages/pronouns/any.vue?macro=true";
import { default as askAVOqjsHx3OMeta } from "/home/admin/www/pronoms.fr/release/2025-01-28--20-32-14/pages/pronouns/ask.vue?macro=true";
import { default as avoiding2dyiZun0uQMeta } from "/home/admin/www/pronoms.fr/release/2025-01-28--20-32-14/pages/pronouns/avoiding.vue?macro=true";
import { default as indexpU4uTosEfvMeta } from "/home/admin/www/pronoms.fr/release/2025-01-28--20-32-14/pages/pronouns/index.vue?macro=true";
import { default as mirrorSccljABpOVMeta } from "/home/admin/www/pronoms.fr/release/2025-01-28--20-32-14/pages/pronouns/mirror.vue?macro=true";
import { default as pronouniwrf69XD3gMeta } from "/home/admin/www/pronoms.fr/release/2025-01-28--20-32-14/pages/pronouns/pronoun.vue?macro=true";
import { default as sources2PDAURm43wMeta } from "/home/admin/www/pronoms.fr/release/2025-01-28--20-32-14/pages/sources.vue?macro=true";
import { default as teamTvYqR9WosrMeta } from "/home/admin/www/pronoms.fr/release/2025-01-28--20-32-14/pages/team.vue?macro=true";
import { default as terminologyXonlEuCfdZMeta } from "/home/admin/www/pronoms.fr/release/2025-01-28--20-32-14/pages/terminology.vue?macro=true";
import { default as terms9TQj6gUnKZMeta } from "/home/admin/www/pronoms.fr/release/2025-01-28--20-32-14/pages/terms.vue?macro=true";
import { default as user7VFV8g2a13Meta } from "/home/admin/www/pronoms.fr/release/2025-01-28--20-32-14/pages/user.vue?macro=true";
import { default as workshopsozDuzjdFltMeta } from "/home/admin/www/pronoms.fr/release/2025-01-28--20-32-14/pages/workshops.vue?macro=true";
export default [
  {
    name: "admin-abuseReports",
    path: "/admin/abuse-reports",
    meta: abuseReportsN0C3gxmbWJMeta || {},
    component: () => import("/home/admin/www/pronoms.fr/release/2025-01-28--20-32-14/pages/admin/abuseReports.vue")
  },
  {
    name: "admin-audit-log-username-id",
    path: "/admin/audit-log/:username()/:id()",
    component: () => import("/home/admin/www/pronoms.fr/release/2025-01-28--20-32-14/pages/admin/audit-log/[username]/[id].vue")
  },
  {
    name: "admin-blog-editor",
    path: "/admin/blog/editor",
    component: () => import("/home/admin/www/pronoms.fr/release/2025-01-28--20-32-14/pages/admin/blog/editor.vue")
  },
  {
    name: "admin",
    path: "/admin",
    component: () => import("/home/admin/www/pronoms.fr/release/2025-01-28--20-32-14/pages/admin/index.vue")
  },
  {
    name: "admin-moderation",
    path: "/admin/moderation",
    component: () => import("/home/admin/www/pronoms.fr/release/2025-01-28--20-32-14/pages/admin/moderation.vue")
  },
  {
    name: "admin-pendingBans",
    path: "/admin/pending-bans",
    meta: pendingBansATfut1vkiiMeta || {},
    component: () => import("/home/admin/www/pronoms.fr/release/2025-01-28--20-32-14/pages/admin/pendingBans.vue")
  },
  {
    name: "admin-profiles",
    path: "/admin/profiles",
    component: () => import("/home/admin/www/pronoms.fr/release/2025-01-28--20-32-14/pages/admin/profiles.vue")
  },
  {
    name: "admin-timesheets-expenses",
    path: "/admin/timesheets/expenses",
    component: () => import("/home/admin/www/pronoms.fr/release/2025-01-28--20-32-14/pages/admin/timesheets/expenses.vue")
  },
  {
    name: "admin-timesheets",
    path: "/admin/timesheets",
    component: () => import("/home/admin/www/pronoms.fr/release/2025-01-28--20-32-14/pages/admin/timesheets/index.vue")
  },
  {
    name: "admin-timesheets-overview",
    path: "/admin/timesheets/overview",
    component: () => import("/home/admin/www/pronoms.fr/release/2025-01-28--20-32-14/pages/admin/timesheets/overview.vue")
  },
  {
    name: "admin-translations-awaiting",
    path: "/admin/translations/awaiting",
    component: () => import("/home/admin/www/pronoms.fr/release/2025-01-28--20-32-14/pages/admin/translations/awaiting.vue")
  },
  {
    name: "admin-translations-missing",
    path: "/admin/translations/missing",
    component: () => import("/home/admin/www/pronoms.fr/release/2025-01-28--20-32-14/pages/admin/translations/missing.vue")
  },
  {
    name: "admin-users",
    path: "/admin/users",
    component: () => import("/home/admin/www/pronoms.fr/release/2025-01-28--20-32-14/pages/admin/users.vue")
  },
  {
    name: "api",
    path: "/api",
    meta: apivkYTpx9sxUMeta || {},
    component: () => import("/home/admin/www/pronoms.fr/release/2025-01-28--20-32-14/pages/api.vue")
  },
  {
    name: "blog-slug",
    path: "/blog/:slug()",
    meta: _91slug_93gVNNaB2WiOMeta || {},
    component: () => import("/home/admin/www/pronoms.fr/release/2025-01-28--20-32-14/pages/blog/[slug].vue")
  },
  {
    name: "blog",
    path: "/blog",
    meta: indexGIIysmHh77Meta || {},
    component: () => import("/home/admin/www/pronoms.fr/release/2025-01-28--20-32-14/pages/blog/index.vue")
  },
  {
    name: "calendar",
    path: "/calendar/:year?",
    meta: _91_91year_93_938WOgrOCLiFMeta || {},
    component: () => import("/home/admin/www/pronoms.fr/release/2025-01-28--20-32-14/pages/calendar/[[year]].vue")
  },
  {
    name: "calendarDay",
    path: "/calendar/:year()-:month()-:day()",
    meta: _91year_93_45_91month_93_45_91day_93kTZ0KwzmP8Meta || {},
    component: () => import("/home/admin/www/pronoms.fr/release/2025-01-28--20-32-14/pages/calendar/[year]-[month]-[day].vue")
  },
  {
    name: "census-admin",
    path: "/census/admin",
    meta: adminWOo0fcw8aDMeta || {},
    component: () => import("/home/admin/www/pronoms.fr/release/2025-01-28--20-32-14/pages/census/admin.vue")
  },
  {
    name: "census",
    path: "/census",
    meta: indexGxEPfdGLcXMeta || {},
    component: () => import("/home/admin/www/pronoms.fr/release/2025-01-28--20-32-14/pages/census/index.vue")
  },
  {
    name: "contact",
    path: "/contact",
    meta: contactborhQ9E7rlMeta || {},
    component: () => import("/home/admin/www/pronoms.fr/release/2025-01-28--20-32-14/pages/contact.vue")
  },
  {
    name: "design",
    path: "/design",
    component: () => import("/home/admin/www/pronoms.fr/release/2025-01-28--20-32-14/pages/design.vue")
  },
  {
    name: "english",
    path: "/english",
    meta: english4MYHusM8gVMeta || {},
    component: () => import("/home/admin/www/pronoms.fr/release/2025-01-28--20-32-14/pages/english.vue")
  },
  {
    name: "faq",
    path: "/faq",
    meta: faquoiU4QuT9jMeta || {},
    component: () => import("/home/admin/www/pronoms.fr/release/2025-01-28--20-32-14/pages/faq.vue")
  },
  {
    name: "inclusive",
    path: "/inclusive",
    meta: inclusiveocRPyjeObpMeta || {},
    component: () => import("/home/admin/www/pronoms.fr/release/2025-01-28--20-32-14/pages/inclusive.vue")
  },
  {
    name: "index-home",
    path: "/index-home",
    meta: index_45home2pdieyBA8lMeta || {},
    component: () => import("/home/admin/www/pronoms.fr/release/2025-01-28--20-32-14/pages/index-home.vue")
  },
  {
    name: "index",
    path: "/index-locale",
    meta: index_45localeKAp8LBx43GMeta || {},
    component: () => import("/home/admin/www/pronoms.fr/release/2025-01-28--20-32-14/pages/index-locale.vue")
  },
  {
    name: "langswitch",
    path: "/langswitch",
    meta: langswitchwP95o1T0J8Meta || {},
    component: () => import("/home/admin/www/pronoms.fr/release/2025-01-28--20-32-14/pages/langswitch.vue")
  },
  {
    name: "license",
    path: "/license",
    component: () => import("/home/admin/www/pronoms.fr/release/2025-01-28--20-32-14/pages/license.vue")
  },
  {
    name: "links-academic",
    path: "/links/academic",
    meta: academicIWbnhtvGIwMeta || {},
    component: () => import("/home/admin/www/pronoms.fr/release/2025-01-28--20-32-14/pages/links/academic.vue")
  },
  {
    name: "links",
    path: "/links",
    meta: indexElA9vz3sE6Meta || {},
    component: () => import("/home/admin/www/pronoms.fr/release/2025-01-28--20-32-14/pages/links/index.vue")
  },
  {
    name: "links-media",
    path: "/links/media",
    meta: mediaStruVcI21qMeta || {},
    component: () => import("/home/admin/www/pronoms.fr/release/2025-01-28--20-32-14/pages/links/media.vue")
  },
  {
    name: "links-translinguistics",
    path: "/links/translinguistics",
    meta: translinguisticszKAuvbmrfQMeta || {},
    component: () => import("/home/admin/www/pronoms.fr/release/2025-01-28--20-32-14/pages/links/translinguistics.vue")
  },
  {
    name: "links-zine",
    path: "/links/zine",
    meta: zine8ov5rm1XcHMeta || {},
    component: () => import("/home/admin/www/pronoms.fr/release/2025-01-28--20-32-14/pages/links/zine.vue")
  },
  {
    name: "names",
    path: "/names",
    meta: names94qeODAon3Meta || {},
    component: () => import("/home/admin/www/pronoms.fr/release/2025-01-28--20-32-14/pages/names.vue")
  },
  {
    name: "nouns",
    path: "/nouns",
    meta: indexCkuEOxDHErMeta || {},
    component: () => import("/home/admin/www/pronoms.fr/release/2025-01-28--20-32-14/pages/nouns/index.vue")
  },
  {
    name: "nouns-templates",
    path: "/nouns/templates",
    meta: templatesdT4HlwgNHGMeta || {},
    component: () => import("/home/admin/www/pronoms.fr/release/2025-01-28--20-32-14/pages/nouns/templates.vue")
  },
  {
    name: "people",
    path: "/people",
    meta: peoplecKO4JDgJhXMeta || {},
    component: () => import("/home/admin/www/pronoms.fr/release/2025-01-28--20-32-14/pages/people.vue")
  },
  {
    name: "privacy",
    path: "/privacy",
    meta: privacyGCjf4l5GAbMeta || {},
    component: () => import("/home/admin/www/pronoms.fr/release/2025-01-28--20-32-14/pages/privacy.vue")
  },
  {
    name: "profile-username",
    path: "/profile/:username()",
    meta: _91username_937SxUShDMbBMeta || {},
    component: () => import("/home/admin/www/pronoms.fr/release/2025-01-28--20-32-14/pages/profile/[username].vue")
  },
  {
    name: "profile-card-username",
    path: "/profile/card-:username()",
    meta: card_45_91username_93LiXPa7TANsMeta || {},
    component: () => import("/home/admin/www/pronoms.fr/release/2025-01-28--20-32-14/pages/profile/card-[username].vue")
  },
  {
    name: "profile-editor",
    path: "/profile/editor",
    meta: editorf4VXGVfOyAMeta || {},
    component: () => import("/home/admin/www/pronoms.fr/release/2025-01-28--20-32-14/pages/profile/editor.vue")
  },
  {
    name: "pronouns-any",
    path: "/pronouns/any",
    meta: anykWmwfa2nKYMeta || {},
    component: () => import("/home/admin/www/pronoms.fr/release/2025-01-28--20-32-14/pages/pronouns/any.vue")
  },
  {
    name: "pronouns-ask",
    path: "/pronouns/ask",
    meta: askAVOqjsHx3OMeta || {},
    component: () => import("/home/admin/www/pronoms.fr/release/2025-01-28--20-32-14/pages/pronouns/ask.vue")
  },
  {
    name: "pronouns-avoiding",
    path: "/pronouns/avoiding",
    meta: avoiding2dyiZun0uQMeta || {},
    component: () => import("/home/admin/www/pronoms.fr/release/2025-01-28--20-32-14/pages/pronouns/avoiding.vue")
  },
  {
    name: "pronouns",
    path: "/pronouns",
    meta: indexpU4uTosEfvMeta || {},
    component: () => import("/home/admin/www/pronoms.fr/release/2025-01-28--20-32-14/pages/pronouns/index.vue")
  },
  {
    name: "pronouns-mirror",
    path: "/pronouns/mirror",
    meta: mirrorSccljABpOVMeta || {},
    component: () => import("/home/admin/www/pronoms.fr/release/2025-01-28--20-32-14/pages/pronouns/mirror.vue")
  },
  {
    name: "all",
    path: "/:path(.*)",
    meta: pronouniwrf69XD3gMeta || {},
    component: () => import("/home/admin/www/pronoms.fr/release/2025-01-28--20-32-14/pages/pronouns/pronoun.vue")
  },
  {
    name: "sources",
    path: "/sources",
    meta: sources2PDAURm43wMeta || {},
    component: () => import("/home/admin/www/pronoms.fr/release/2025-01-28--20-32-14/pages/sources.vue")
  },
  {
    name: "team",
    path: "/team",
    meta: teamTvYqR9WosrMeta || {},
    component: () => import("/home/admin/www/pronoms.fr/release/2025-01-28--20-32-14/pages/team.vue")
  },
  {
    name: "terminology",
    path: "/terminology",
    meta: terminologyXonlEuCfdZMeta || {},
    component: () => import("/home/admin/www/pronoms.fr/release/2025-01-28--20-32-14/pages/terminology.vue")
  },
  {
    name: "terms",
    path: "/terms",
    meta: terms9TQj6gUnKZMeta || {},
    component: () => import("/home/admin/www/pronoms.fr/release/2025-01-28--20-32-14/pages/terms.vue")
  },
  {
    name: "user",
    path: "/user",
    meta: user7VFV8g2a13Meta || {},
    component: () => import("/home/admin/www/pronoms.fr/release/2025-01-28--20-32-14/pages/user.vue")
  },
  {
    name: "workshops",
    path: "/workshops",
    meta: workshopsozDuzjdFltMeta || {},
    component: () => import("/home/admin/www/pronoms.fr/release/2025-01-28--20-32-14/pages/workshops.vue")
  }
]